import './index.scss';
import React from 'react';

import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import PropTypes from 'prop-types';

import HeaderNav from '../HeaderNav';

const FinePrint = ({ data, pageContext, location }) => {
  let fineprint = data.allContentfulFinePrint.nodes[0];

  return (
    <main>
      <section className="fineprint site-wrap">
        <h3>{fineprint.header}</h3>
        <p>{fineprint.subheader}</p>
        {fineprint.file ? (
          <iframe
            src={fineprint.file.url}
            style={{ width: '90%', height: '90vh', border: '0' }}
          ></iframe>
        ) : (
          <p>{renderRichText(fineprint.body)}</p>
        )}
      </section>
    </main>
  );
};

export const data = graphql`
  query($slug: String, $version: Int) {
    allContentfulFinePrint(
      filter: { slug: { eq: $slug }, version: { eq: $version } }
    ) {
      nodes {
        header
        subheader
        body {
          raw
        }
        file {
          url
        }
        slug
      }
    }
  }
`;
FinePrint.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
};

export default FinePrint;
